import { environment } from '@app/../environments/environment';
import config from '@rootConfig/config.json';
import { CodeRequestResultType } from '@shared/models/code-request-result.enum';
import { TenantResource } from '@shared/models/tenant.model';

export default {
  ...config,
  ...environment,
  tenants: {
    ALM: {
      id: 'ALM',
      name: 'Almirall',
    },
    APG: {
      id: 'APG',
      name: 'Apotheco',
    },
    BHC: {
      id: 'BHC',
      name: 'Bausch Health',
    },
    DMO: {
      id: 'DMO',
      name: 'Demo',
    },
    GLD: {
      id: 'GLD',
      name: 'Galderma',
    },
    INCY: {
      id: 'INCY',
      name: 'Incyte',
    },
  },
  care: {
    patientConsent: {
      businessDays: '2-4',
    },
  },
  componentsVisible: {
    PaRequestsListComponent: ['ALM'],
    AppealRequestsListComponent: ['INCY'],
    FaqComponent: ['ALM', 'INCY'],
    TableWrapperComponent: ['ALM', 'BHC', 'GLD', 'INCY', 'DMO'],
    TableBrowserComponent: ['ALM', 'BHC', 'GLD', 'INCY', 'DMO'],
    WorkflowVisualizerComponent: ['ALM', 'BHC', 'GLD', 'INCY', 'DMO'],
  },
  paRequestList: {
    highlightPayors: [] as string[],
  },
  inventorySettingsTenants: ['ALM', 'INCY'],
  scorecardSettingsTenants: ['INCY'],
  supportPhone: '833-613-2333',
  businessRules: {
    externalComparison: <Record<string, boolean>>{
      DMO: true,
    },
    tableEditor: {
      import: {
        allowedTables: ['workflow_hcp_pharmacy_mappings_HZN'],
        sender: 'oppc',
        addRoute: 'oppc-open_version_add-',
        updateRoute: 'oppc-open_version_update-',
      },
      export: {
        etlTables: [
          'workflow_hcp_pharmacy_mappings_HZN',
          'workflow_hcp_override_HZN',
          'workflow_insurance_max_copay_HZN',
          'workflow_insurance_model_HZN',
          'workflow_insurance_redirect_HZN',
          'workflow_insurance_state_paid_HZN',
          'workflow_max_copay_HZN',
          'workflow_pharmacy_insurance_state_paid_HZN',
          'workflow_pharmacy_max_daily_nrx_HZN',
          'workflow_pharmacy_state_excl_HZN',
          'workflow_state_default_pharmacies_HZN',
          'workflow_triage_pharmacy_HZN',
          'workflow_insurance_pharmacy_max_daily_nrx_HZN',
        ],
        mapIds: {
          workflow_hcp_override_HZN: 'hcp_override_mappings_export_report',
          workflow_insurance_max_copay_HZN: 'insurance_max_copay_export_report',
          workflow_insurance_model_HZN: 'insurance_model_mappings_export_report',
          workflow_insurance_redirect_HZN: 'insurance_redirect_mappings_export_report',
          workflow_insurance_state_paid_HZN: 'insurance_state_paid_mappings_export_report',
          workflow_max_copay_HZN: 'max_copay_mappings_export_report',
          workflow_pharmacy_insurance_state_paid_HZN: 'pharmacy_insurance_state_paid_mappings_export_report',
          workflow_pharmacy_max_daily_nrx_HZN: 'pharmacy_max_daily_nrx_mappings_export_report',
          workflow_pharmacy_state_excl_HZN: 'pharmacy_state_excl_mappings_export_report',
          workflow_state_default_pharmacies_HZN: 'state_default_pharmacies_mappings_export_report',
          workflow_triage_pharmacy_HZN: 'triage_pharmacy_mappings_export_report',
          workflow_insurance_pharmacy_max_daily_nrx_HZN: 'insurance_pharmacy_max_daily_nrx_mappings_export_report',
        },
      },
    },
  },
  rulesPrograms: {
    ALM: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'alm_copay_assistance',
      },
    ],
    APG: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'apg_copay_assistance',
      },
    ],
    BHC: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'bhc_copay_assistance',
      },
    ],
    GLD: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'gld_copay_assistance',
      },
    ],
    INCY: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'incy_copay_assistance',
      },
    ],
    DMO: [
      {
        id: 'copay-request',
        label: 'Copay Request Workflow',
        groupId: 'copay_base_data',
        schemaId: 'dmo_copay_assistance',
      },
    ],
  },
  deltaPages: {
    ALM: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
    APG: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
    BHC: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
    GLD: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
    INCY: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
    DMO: [
      { path: 'table-browser', label: 'Table Browser', component: 'TableBrowserComponent', visible: false },
      { path: 'workflow', label: 'Workflow', component: 'WorkflowVisualizerComponent', visible: false },
    ],
  },
  resources: <Record<string, TenantResource[]>>{
    ALM: [
      {
        category: 'ProgramInformation',
        name: 'Almirall Advantage Program Playbook',
        file_name: 'Almirall_Advantage_Program_Playbook.pptx',
      },
      {
        category: 'ProgramInformation',
        name: 'Medicare-Cash Pay Training',
        file_name: 'Medicare-Cash_Pay_Training.pptx',
      },
      {
        category: 'ProgramInformation',
        name: 'Copay Summary 2023 Table',
        file_name: 'Copay_Summary_2023_Table.pdf',
      },
    ],
    APG: [],
    BHC: [],
    DMO: [
      {
        category: 'ProgramInformation',
        name: 'Galderma Care Connect Summary',
        file_name: 'Galderma_Care_Connect_Summary_Table.pdf',
      },
    ],
    GLD: [
      {
        category: 'ProgramInformation',
        name: 'Galderma Care Connect Summary',
        file_name: 'Galderma_Care_Connect_Summary_Table.pdf',
      },
    ],
    INCY: [
      {
        category: 'ProgramInformation',
        name: 'Get To Know Your Bi-Weekly Replenishment Schedule for OPZELURA™',
        subtitle:
          'Incyte follows an order consolidation process that involves its batching of replenishment orders for OPZELURA™ based on several prior days of brightscrip-authorized claims in a single shipment.',
        button_label: 'Click here for Infographic',
        file_name: 'opzelura_replenishment_schedule.pdf',
        colors: {
          cardColor: '#2D2850',
          cardTitleColor: '#fff',
          cardSubtitleColor: '#fff',
          ctaButtonBgColor: '#E15067',
          ctaButtonLabelColor: '#fff',
        },
      },
      {
        category: 'ProgramInformation',
        name: 'Get to know your brightChart™ BETA',
        subtitle:
          'Let’s take a closer look at brightChart™ as a new means to gain visibility of your pharmacy’s activity in connection with manufacturer-sponsored copay programs.',
        button_label: 'Click here to Learn More',
        file_name: 'scorecard_incy_slide3.pdf',
        colors: {
          cardColor: '#1A1A1A',
          cardTitleColor: '#fff',
          cardSubtitleColor: '#fff',
          ctaButtonBgColor: '#FF7083',
          ctaButtonLabelColor: '#fff',
        },
      },
    ],
  },
  claimResultTypes: {
    ALM: [
      CodeRequestResultType.Consignment,
      CodeRequestResultType.Duplicate,
      CodeRequestResultType.InProgress,
      CodeRequestResultType.Incomplete,
      CodeRequestResultType.Paid,
      CodeRequestResultType.PaidEligible,
      CodeRequestResultType.Rejected,
      CodeRequestResultType.Reversed,
      CodeRequestResultType.PaPending,
    ],
    APG: [CodeRequestResultType.Duplicate, CodeRequestResultType.Paid, CodeRequestResultType.Rejected, CodeRequestResultType.Reversed],
    BHC: [CodeRequestResultType.Duplicate, CodeRequestResultType.Paid, CodeRequestResultType.Rejected, CodeRequestResultType.Reversed],
    DMO: [CodeRequestResultType.Duplicate, CodeRequestResultType.Paid, CodeRequestResultType.Rejected, CodeRequestResultType.Reversed],
    GLD: [CodeRequestResultType.Duplicate, CodeRequestResultType.Paid, CodeRequestResultType.Rejected, CodeRequestResultType.Reversed],
    INCY: [
      CodeRequestResultType.AppealComplete,
      CodeRequestResultType.AppealPending,
      CodeRequestResultType.Duplicate,
      CodeRequestResultType.Incomplete,
      CodeRequestResultType.Paid,
      CodeRequestResultType.Rejected,
      CodeRequestResultType.Reversed,
      CodeRequestResultType.Transferred,
      CodeRequestResultType.NotTransferred,
    ],
  },
  termsAndConditions: ['BHC', 'GLD', 'INCY'],
};
