import { Injectable, OnDestroy } from '@angular/core';
import { Observable, PartialObserver, Subject, Subscription } from 'rxjs';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  protected readonly destroyed = new Subject<void>();

  protected readonly subscriptions = new Subscription();

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.subscriptions.unsubscribe();
  }

  protected subscribe<T>(observable: Observable<T>, next?: (value: T) => void): Subscription;
  protected subscribe<T>(observable: Observable<T>, observer: PartialObserver<T>): Subscription;
  protected subscribe<T>(observable: Observable<T>, observerOrNext: any): Subscription {
    const observer = observerOrNext instanceof Function ? { next: observerOrNext } : observerOrNext;
    const subscription = observable.subscribe(observer);
    this.subscriptions.add(subscription);
    return subscription;
  }
}
