import dayjs, { ManipulateType, OpUnitType, QUnitType } from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(dayjsBusinessTime);

export interface DateRange {
  start: Date;
  end: Date;
}

export class DateUtility {
  public static MILLISECONDS_FIXED_DATE: string;
  public static readonly ISO_DATE_FORMAT = 'YYYY-MM-DD';
  public static readonly US_DATE_FORMAT = 'MM/DD/YYYY';
  public static readonly US_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
  public static readonly CHICAGO_OFFSET_MINUTES = 5 * 60;

  public static cloneDate(date: Date): Date {
    return dayjs(date).toDate();
  }

  public static createDateFromFormat(dateString: string, format: string): Date {
    return dayjs(dateString, format).toDate();
  }

  public static formatDate(date: string | Date, format: string): string {
    return dayjs(date).format(format);
  }

  public static formatDateWithOffset(date: string | Date, format: string, offset = 0): string {
    return dayjs(date).utcOffset(-offset).format(format);
  }

  public static addTime(date: Date, value: number, unit: ManipulateType): Date {
    return dayjs(date).add(value, unit).toDate();
  }

  public static dateToDayString(date: Date, format = DateUtility.ISO_DATE_FORMAT): string {
    return dayjs(date).format(format);
  }

  public static dateToIsoStringWithoutMilliseconds(date: Date): string {
    return date.toISOString().split('.')[0] + 'Z';
  }

  public static dateOfBirthToAge(dateOfBirth: Date, decisiveDay = new Date()): number {
    return dayjs(decisiveDay).diff(dayjs(dateOfBirth), 'year', true);
  }

  public static toUtcDate(date: Date, offset: number): Date {
    return dayjs(date).utcOffset(offset, true).toDate();
  }

  public static toLocalDate(date: Date, offset: number): Date {
    return dayjs(date).utcOffset(-offset, true).toDate();
  }

  public static dateToUtcIsoStringWithoutMilliseconds(date: Date, offsetFromUtc = 0): string {
    const utcDate = this.toUtcDate(date, offsetFromUtc);

    return this.dateToIsoStringWithoutMilliseconds(utcDate);
  }

  public static dateToUtcIsoString(date: Date, offsetFromUtc = 0): string {
    const utcDate = this.toUtcDate(date, offsetFromUtc);
    let isoString: string;

    if (dayjs(utcDate).isBefore(this.MILLISECONDS_FIXED_DATE)) {
      isoString = this.dateToIsoStringWithoutMilliseconds(utcDate);
    } else {
      isoString = dayjs(utcDate).toISOString();
    }

    return isoString;
  }

  public static createDateRangeFromStringDates(start: string, end: string, format: string = DateUtility.ISO_DATE_FORMAT): DateRange {
    const startDate = this.createDateFromFormat(start, format);
    const endDate = this.createDateFromFormat(end, format);

    return { start: startDate, end: endDate };
  }

  public static getWeekRangeForDay(day: Date): DateRange {
    const startOfWeek = dayjs(day).startOf('week').toDate();
    const endOfWeek = dayjs(day).endOf('week').toDate();

    return { start: startOfWeek, end: endOfWeek };
  }

  public static isDateBetweenDates(date: Date, startDate?: Date, endDate?: Date): boolean {
    let result = true;

    if (startDate && endDate) {
      result = dayjs(date).isBetween(startDate, endDate);
    } else if (startDate) {
      result = dayjs(date).isSameOrAfter(startDate);
    } else if (endDate) {
      result = dayjs(date).isSameOrBefore(endDate);
    }

    return result;
  }

  public static getDuration(startDate: Date, endDate: Date, unit?: QUnitType | OpUnitType, float?: boolean, abs = true): number {
    const duration = dayjs(startDate).diff(dayjs(endDate), unit, float);

    return abs ? Math.abs(duration) : duration;
  }

  public static getCountdown(decisiveDate: Date, endDate: Date, countdownIsFinishedMessage = 'The countdown is finished.'): string {
    let countdown: string;

    if (decisiveDate < endDate) {
      const hours = Math.trunc(this.getDuration(decisiveDate, endDate, 'hours', true));
      const minutes = this.getDuration(this.addTime(decisiveDate, hours, 'hours'), endDate, 'minutes', false);

      const hoursCountdown = hours >= 1 ? [hours, hours === 1 ? 'hour' : 'hours'].join(' ') : undefined;
      const minutesCountdown = minutes >= 1 ? [minutes, minutes === 1 ? 'minute' : 'minutes'].join(' ') : undefined;

      countdown = [hoursCountdown, minutesCountdown].filter(item => item).join(', ') || 'less than minute';
    } else {
      countdown = countdownIsFinishedMessage;
    }

    return countdown;
  }

  public static compare(firstDate: Date, secondDate: Date): -1 | 0 | 1 {
    let result: -1 | 0 | 1;

    if (dayjs(firstDate).isBefore(dayjs(secondDate))) {
      result = -1;
    } else if (dayjs(firstDate).isAfter(dayjs(secondDate))) {
      result = 1;
    } else {
      result = 0;
    }

    return result;
  }

  public static addBusinessDays(date: Date | string, days: number): Date {
    const dayjsDate = dayjs(date);
    const year = dayjsDate.year();

    dayjs.setHolidays(DateUtility.getHolidaysForYears([year, year + 1]));

    if (days > 0) {
      date = dayjsDate.addBusinessDays(days).toDate();
    } else {
      date = dayjsDate.subtractBusinessDays(days * -1).toDate();
    }

    return date;
  }

  public static isWeekend(date: Date): boolean {
    return date.getDay() == 6 || date.getDay() == 0;
  }

  public static isHoliday(date: Date): boolean {
    const day = dayjs(date).format('MM-DD');

    return DateUtility.getHolidays().includes(day);
  }

  public static getUnixTimestamp(date: Date): number {
    return dayjs(date).unix();
  }

  public static getHolidays(): string[] {
    return [
      '01-01', // New Year
      '07-04', // Independence Day
      '12-24', // Christmas Eve
      '12-25', // Christmas Day
      '12-31', // New Years Eve
    ];
  }

  public static getHolidaysForYears(years: number[]): string[] {
    const holyDays = DateUtility.getHolidays();

    return years.reduce((partialHolidays: string[], year) => {
      holyDays.forEach(holyDays => partialHolidays.push(`${year}-${holyDays}`));

      return partialHolidays;
    }, []);
  }

  public static getStartDayOfDate(date: Date | string): Date {
    let day: string;

    if (date instanceof Date) {
      day = dayjs(date).format('YYYY-MM-DD');
    } else {
      day = date.substring(0, 10);
    }

    return new Date(`${day}T00:00:00.000Z`);
  }

  public static getEndDayOfDate(date: Date | string): Date {
    let day: string;

    if (date instanceof Date) {
      day = dayjs(date).format('YYYY-MM-DD');
    } else {
      day = date.substring(0, 10);
    }

    return new Date(`${day}T23:59:59.999Z`);
  }
}
