import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AUTH_REQUIRED } from '@app/tokens';
import { SignInOutput, confirmSignIn, fetchAuthSession, signIn } from '@aws-amplify/auth';
import config from '@config';
import { TokenClaimResponse } from '@services/models/token-claim-response';
import { UIAnalyticsService } from '@services/ui-analytics.service';
import { UrlService } from '@services/url.service';
import { DateUtility } from '@shared/date-utility';
import { IRole } from '@shared/models/role.model';
import { IUser } from '@shared/models/user.model';
import { isError } from 'lodash';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

export type LoginNextStep = SignInOutput['nextStep']['signInStep'];
export type LoginResult = { signedIn: boolean; nextStep?: LoginNextStep; error?: string };
export type TokenIdentity = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: 'Bearer';
  userName: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly USER_SESSION_KEY = '_bus';
  readonly USER_ROLE_KEY = '_bur';
  readonly ACCESS_TOKEN_KEY = '_accessToken';
  readonly REFRESH_TOKEN_EXPIRATION = '_refreshTokenExp';
  readonly TOKEN_EXPIRATION = '_tokenExpiration';
  readonly TOKENIZED_LOGIN_TOKEN = '_loginToken';
  readonly LOGOUT_INITIATED = '_initLogout';
  readonly LOGOUT_INITIATED_VALUE = 'true';

  private analyticsService = inject(UIAnalyticsService);
  private httpClient = inject(HttpClient);
  private router = inject(Router);
  private urlService = inject(UrlService);

  authStatus = signal(this.isUserAuthenticated());
  authStatusChanged = toObservable(this.authStatus);

  async login(username: string, password: string): Promise<LoginResult> {
    try {
      localStorage.clear();
      const result = await signIn({ username, password });
      const session = await fetchAuthSession();
      if (result.isSignedIn && session.tokens?.idToken) {
        const cognitoUsername = session.tokens.idToken?.payload['cognito:username'] as string;
        await this.onLogin(session.tokens.idToken.toString(), cognitoUsername ?? username, password);
        return { signedIn: true };
      }
      return { signedIn: false, nextStep: result.nextStep.signInStep };
    } catch (err) {
      const error = isError(err) ? err : new Error('Login has failed');
      console.error(err);
      return { signedIn: false, error: error.message };
    }
  }

  async onLogin(accessToken: string, username: string, password?: string) {
    this.setAccessToken(accessToken);
    this.setRefreshTokenExpiration();
    const user = await firstValueFrom(this.retrieveUser(username, password), { defaultValue: null });
    if (user) {
      this.analyticsService.trackLogin(user);
      await firstValueFrom(this.retrieveUserRole(user.role), { defaultValue: null });
    }
    this.authStatus.set(true);
  }

  async tokenizedLogin({ identity, username }: TokenClaimResponse) {
    const namespace = `CognitoIdentityServiceProvider.${config.cognito.appClientId}`;
    localStorage.clear();
    localStorage.setItem(`${namespace}.LastAuthUser`, username);
    localStorage.setItem(`${namespace}.${username}.accessToken`, identity.access_token);
    localStorage.setItem(`${namespace}.${username}.idToken`, identity.id_token);
    localStorage.setItem(`${namespace}.${username}.refreshToken`, identity.refresh_token);
    localStorage.setItem(this.TOKEN_EXPIRATION, moment().add(24, 'hours').toISOString());
    await this.onLogin(identity.id_token, username);
  }

  async tokenLogin(identity: TokenIdentity) {
    const namespace = `CognitoIdentityServiceProvider.${config.cognito.appClientId}`;
    localStorage.clear();
    localStorage.setItem(`${namespace}.LastAuthUser`, identity.userName);
    localStorage.setItem(`${namespace}.${identity.userName}.accessToken`, identity.accessToken);
    localStorage.setItem(`${namespace}.${identity.userName}.idToken`, identity.idToken);
    localStorage.setItem(`${namespace}.${identity.userName}.refreshToken`, identity.refreshToken);
    localStorage.setItem(this.TOKEN_EXPIRATION, moment().add(24, 'hours').toISOString());
    await this.onLogin(identity.idToken, identity.userName);
  }

  async confirmPass(username: string, password: string): Promise<LoginResult> {
    try {
      localStorage.clear();
      const result = await confirmSignIn({ challengeResponse: password });
      const session = await fetchAuthSession();
      if (result.isSignedIn && session.tokens?.idToken) {
        await this.onLogin(session.tokens.idToken.toString(), username, password);
        return { signedIn: true };
      }
      return { signedIn: false, nextStep: result.nextStep.signInStep };
    } catch (err) {
      const error = isError(err) ? err : new Error('Confirm Password has failed');
      console.error(err);
      return { signedIn: false, error: error.message };
    }
  }

  logout(reason: string, redirectToLogin = true) {
    console.log(`Logout: ${reason}`);
    this.authStatus.set(false);
    this.analyticsService.trackLogout();
    localStorage.setItem(this.LOGOUT_INITIATED, this.LOGOUT_INITIATED_VALUE);
    localStorage.clear();
    sessionStorage.clear();
    if (redirectToLogin) this.router.navigate(['/login']);
  }

  forgotPassword(username: string) {
    const body = {
      clientId: config.cognito.appClientId,
      username,
    };
    return this.httpClient.post(this.urlService.FORGOT_PASSWORD_URL, body, {
      context: new HttpContext().set(AUTH_REQUIRED, false),
    });
  }

  resetPassword(username: string, password: string, confirmationCode: string) {
    const body = {
      clientId: config.cognito.appClientId,
      confirmationCode,
      username,
      password,
    };
    return this.httpClient.post(this.urlService.CONFIRM_NEW_PASSWORD_URL, body, {
      context: new HttpContext().set(AUTH_REQUIRED, false),
    });
  }

  changePassword(oldPassword: string, newPassword: string) {
    const body = {
      username: this.getCurrentUser().username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    return this.httpClient.post(this.urlService.CHANGE_PASSWORD_URL, body);
  }

  decodeJwtToken(token: string): Record<string, any> {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      console.debug(error);
      return {};
    }
  }

  getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  setAccessToken(token: string) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
  }

  getRefreshTokenExpiration() {
    const exp = localStorage.getItem(this.REFRESH_TOKEN_EXPIRATION);
    return exp?.length ? Number(exp) : null;
  }

  setRefreshTokenExpiration() {
    const expiration = DateUtility.addTime(new Date(), 12, 'hours');
    localStorage.setItem(this.REFRESH_TOKEN_EXPIRATION, expiration.getTime().toString());
  }

  isAccessTokenExpired() {
    const payload = this.decodeJwtToken(this.getAccessToken() ?? '');
    const exp = Number(payload.exp ?? 0);
    return exp < Date.now();
  }

  isRefreshTokenExpired() {
    const exp = Number(localStorage.getItem(this.REFRESH_TOKEN_EXPIRATION));
    return exp < Date.now();
  }

  isUserAuthenticated() {
    return (
      (!this.isAccessTokenExpired() || !this.isRefreshTokenExpired()) &&
      !!localStorage.getItem(this.USER_SESSION_KEY) &&
      !!localStorage.getItem(this.USER_ROLE_KEY)
    );
  }

  isTokenizedLogin() {
    return localStorage.getItem(this.TOKEN_EXPIRATION) !== null;
  }

  getTokenizedLoginToken() {
    return localStorage.getItem(this.TOKENIZED_LOGIN_TOKEN);
  }

  saveUser(user: IUser) {
    localStorage.setItem(this.USER_SESSION_KEY, JSON.stringify(user));
  }

  getCurrentUser(): IUser {
    const userData = localStorage.getItem(this.USER_SESSION_KEY);
    if (!userData) {
      throw new Error('Getting user in non-user space!');
    }
    return JSON.parse(userData);
  }

  getCurrentUserRole(): IRole {
    const roleData = localStorage.getItem(this.USER_ROLE_KEY);
    if (!roleData) {
      throw new Error('Getting user role in non-user space!');
    }
    return JSON.parse(roleData);
  }

  redirectToStartingPage() {
    const rootPage = this.urlService.isLogin() || this.urlService.isRoot();
    const redirectTo = new URL(document.location.toString()).searchParams.get('redirectTo');
    if (redirectTo && rootPage) {
      this.router.navigate([redirectTo]);
      return;
    }
    const userRole = this.getCurrentUserRole();
    if (userRole.default_route && rootPage) {
      this.router.navigate([userRole.default_route]);
    }
  }

  private retrieveUser(username: string, password?: string) {
    return this.httpClient.get<IUser>(`${this.urlService.USER_URL}/${username.trim()}`).pipe(
      tap(user => localStorage.setItem(this.USER_SESSION_KEY, JSON.stringify(user))),
      tap(user => (!user.sync_date && password ? this.syncUserCredentials(username, password) : null)),
    );
  }

  private retrieveUserRole(roleId: string) {
    return this.httpClient
      .get<IRole>(`${this.urlService.ROLES_URL}/${roleId}`)
      .pipe(tap(role => localStorage.setItem(this.USER_ROLE_KEY, JSON.stringify(role))));
  }

  private syncUserCredentials(username: string, password: string) {
    const body = { password };
    this.httpClient.patch(`${this.urlService.USER_URL}/${username}`, body).subscribe({
      next: console.log,
      error: console.error,
    });
  }

  async fetchAccessToken() {
    const result = await fetchAuthSession();
    if (result.tokens?.idToken) {
      return result.tokens.idToken.toString();
    }
    return null;
  }
}
