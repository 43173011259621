import { Injectable } from '@angular/core';
import config from '@config';
import { NgbDate, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateUtility } from '@shared/date-utility';
import { DateFormat } from '@shared/models/date-format.enum';
import { IMonth } from '@shared/models/month.model';
import { isString } from 'lodash';
import { DurationInputArg2, FromTo, MomentInput, unitOfTime } from 'moment';
import moment from 'moment-timezone';

export type TimeUnit = 'days' | 'hours' | 'minutes';

@Injectable()
export class DateService {
  static DEFAULT_API_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  static IN_PROGRESS = 'In Progress';
  static BEGINNING_OF_BRIGHTSCRIP_DATE = '2018-09-28';
  static ISO_DATE_FORMAT = 'YYYY-MM-DD';
  static LONG_DATE_FORMAT = 'MMMM D, YYYY';
  static US_DATE_FORMAT = 'MM/DD/YYYY';

  monthsOfYear: IMonth[] = [
    { month: 'January', monthNumber: 1, daysInMonth: 31 },
    { month: 'February', monthNumber: 2, daysInMonth: 28 },
    { month: 'March', monthNumber: 3, daysInMonth: 31 },
    { month: 'April', monthNumber: 4, daysInMonth: 30 },
    { month: 'May', monthNumber: 5, daysInMonth: 31 },
    { month: 'June', monthNumber: 6, daysInMonth: 30 },
    { month: 'July', monthNumber: 7, daysInMonth: 31 },
    { month: 'August', monthNumber: 8, daysInMonth: 31 },
    { month: 'September', monthNumber: 9, daysInMonth: 31 },
    { month: 'October', monthNumber: 10, daysInMonth: 31 },
    { month: 'November', monthNumber: 11, daysInMonth: 30 },
    { month: 'December', monthNumber: 12, daysInMonth: 31 },
  ];

  formatInput(input: MomentInput, format: string): string {
    return moment(input).format(format);
  }

  getDurationBetweenObjectStartAndEnd(object: Record<string, any>, fieldForStartTime = 'created'): string {
    if (object && object[fieldForStartTime] && object.end) {
      return this.getDurationBetweenStartAndEnd(object[fieldForStartTime], object.end);
    }
    return DateService.IN_PROGRESS;
  }

  calculateTimeAndUnit(givenMoment: moment.Moment): [number, TimeUnit] {
    const daysDifference = Math.trunc(this.getDifferenceBetweenNowAndMoment(givenMoment, 'days'));
    const minutesDifference = Math.trunc(this.getDifferenceBetweenNowAndMoment(givenMoment, 'minutes'));
    const durationInHours = Math.trunc(this.getDifferenceBetweenNowAndMoment(givenMoment, 'hours'));

    if (daysDifference > 0) {
      return [daysDifference, 'days'];
    }
    if (minutesDifference < 60) {
      return [minutesDifference, 'minutes'];
    }
    return [durationInHours, 'hours'];
  }

  calculateAge(dob: string): number {
    return Math.floor(moment().diff(moment(dob), 'years', true));
  }

  calculateNextBirthdayInDays(date: MomentInput): number {
    const birthday = moment(date).startOf('day');
    const today = moment().startOf('day');
    const age = today.diff(birthday, 'years');
    let nextBirthday = moment(birthday).add(age, 'years');
    let days = 0;

    if (!nextBirthday.isSame(today)) {
      nextBirthday = moment(birthday).add(age + 1, 'years');
      days = nextBirthday.diff(today, 'days');
    }

    return days;
  }

  private getDifferenceBetweenNowAndMoment(givenMoment: moment.Moment, unit: moment.unitOfTime.Diff) {
    return Math.abs(this.getNow().diff(givenMoment, unit));
  }

  getDurationBetweenStartAndEnd(start: Date, end: Date): string {
    if (start && end) {
      const _start = moment(start);
      const _end = moment(end);
      const duration = moment.duration(_start.diff(_end));

      let seconds = Math.trunc(Math.abs(duration.asSeconds()));
      const minutes = Math.trunc(Math.abs(duration.asMinutes()));

      if (minutes > 0) {
        seconds = seconds - minutes * 60;
        return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds);
      }
      return ':' + (seconds < 10 ? '0' + seconds : seconds);
    }
    return DateService.IN_PROGRESS;
  }

  formatDateForUTC(date: Date, format: string): string {
    return this.formatDateForApi(date, format);
  }

  formatDateForApi(date: Date, format?: string): string {
    if (format) {
      return moment(date).utc().format(format);
    }

    return this.toISOStringFixedMs(date);
  }

  formatLocalDateForApi(date: Date, format?: string): string {
    if (format) {
      return moment(date).format(format);
    }

    return moment(date).format(DateService.DEFAULT_API_FORMAT) + 'Z';
  }

  toUtcString(date: Date): string {
    const isoDate = date.toISOString();
    const msIndex = isoDate.lastIndexOf('.');
    return isoDate.substring(0, msIndex) + 'Z';
  }

  getDateFromNgbDate(ngb_date: NgbDateStruct): Date {
    const year = ngb_date.year;
    const month = ngb_date.month - 1;
    const day = ngb_date.day;
    return new Date(year, month, day);
  }

  getStartOfDayUtc(date: MomentInput): moment.Moment {
    return moment(date).startOf('day').utc();
  }

  getStartOfDay(date: MomentInput): moment.Moment {
    return moment(date).startOf('day');
  }

  getStartOfDayWithOffsetAdjustment(): moment.Moment {
    const startOfTodayUTC: moment.Moment = this.getNow().startOf('day').utc();
    const defaultOffsetFromUtc = config.defaultOffsetFromUtc;
    const offsetDifferenceInHour = defaultOffsetFromUtc - startOfTodayUTC.hour();
    return moment(startOfTodayUTC).add(offsetDifferenceInHour, 'hours');
  }

  getYesterday(): Date {
    return this.getNow().subtract(1, 'days').startOf('day').toDate();
  }

  get24HoursAgo(): Date {
    return this.getNow().subtract(1, 'days').toDate();
  }

  getEndOfDay(date: MomentInput) {
    return moment(date).endOf('day');
  }

  getEndOfDayUtc(date: MomentInput) {
    return moment(date).endOf('day').utc();
  }

  getStartOfMonth(): Date {
    return this.getNow().startOf('month').toDate();
  }

  getStartOfWeek(): Date {
    return this.getNow().startOf('week').toDate();
  }

  getStartOfIsoWeek(): Date {
    return this.getNow().startOf('isoWeek').toDate();
  }

  getEndOfIsoWeek(): Date {
    return this.getNow().endOf('isoWeek').toDate();
  }

  getStartOfYear() {
    return this.getNow().startOf('year').toDate();
  }

  getPreviousMonday(): moment.Moment {
    return this.getNow().startOf('isoWeek');
  }

  getPreviousDayOfWeek(date: Date, isoDayOfWeek: number): moment.Moment {
    let momentDate = moment(date);
    do {
      momentDate = momentDate.subtract(1, 'days');
    } while (momentDate.isoWeekday() != isoDayOfWeek);
    return momentDate;
  }

  getPreviousMondayFormatted(): string {
    const defaultOffsetFromUtc = config.defaultOffsetFromUtc;
    let lastMonday = this.getPreviousMonday().add(defaultOffsetFromUtc, 'hours');
    if (lastMonday.toDate() > new Date()) {
      lastMonday = lastMonday.add(-1, 'weeks');
    }

    const formatted = lastMonday.format('YYYY-MM-DDTHH').toString();
    this.log(`lastMonday UTC: ${formatted}`);
    return formatted;
  }

  getNextMonday() {
    const dayINeed = 1; // for Monday

    if (this.getNow().isoWeekday() < dayINeed) {
      return this.getNow().isoWeekday(dayINeed);
    } else {
      return this.getNow().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  getNextMondayWithOffsetAdjustment(): moment.Moment {
    const defaultOffsetFromUtc = config.defaultOffsetFromUtc;
    const nextMonday = this.getNextMonday().startOf('day').utc();
    const offsetDifferenceInHour = defaultOffsetFromUtc - nextMonday.hour();
    return moment(nextMonday).add(offsetDifferenceInHour, 'hours');
  }

  getNextMondayFormatted(): string {
    const nextMonday = this.getNextMonday();
    return nextMonday.format('MM/DD/YY').toString();
  }

  getTomorrowWithOffsetAdjustment(): moment.Moment {
    const defaultOffsetFromUtc = config.defaultOffsetFromUtc;
    const tomorrow = this.getNow().startOf('day').add(1, 'day').utc();
    const offsetDifferenceInHour = defaultOffsetFromUtc - tomorrow.hour();
    return moment(tomorrow).add(offsetDifferenceInHour, 'hours');
  }

  getFirstDayOfQuarter(): Date {
    const now = this.getNow().toDate();
    const monthInQuarterIndex = Math.floor(now.getMonth() / 3);
    return new Date(now.getFullYear(), monthInQuarterIndex * 3, 1);
  }

  getFirstDayOfQuarterFormatted(format?: string): string {
    const quarterDate = this.getFirstDayOfQuarter();
    return moment(quarterDate).format(format === undefined ? DateService.LONG_DATE_FORMAT : format);
  }

  getNowFormatted(): string {
    const now = this.getNow().toDate();
    return this.formatDateForApi(now);
  }

  getLocalNowFormatted(): string {
    const now = this.getNow().toDate();
    return this.formatLocalDateForApi(now);
  }

  getStartOfTodayFormatted(format?: string): string {
    const today = this.getNow().startOf('day').toDate();
    return this.formatLocalDateForApi(today, format);
  }

  get24HoursAgoFormatted(): string {
    return this.formatLocalDateForApi(this.get24HoursAgo());
  }

  getPrettyNow() {
    return 'Today - ' + this.getNow().format('MMMM DD, YYYY');
  }

  getPreviousDate(endDate: Date, units: number | string, type: string): Date {
    const date = moment(endDate);
    const newDate = date.subtract(units, type as DurationInputArg2);

    return newDate.toDate();
  }

  getDateWithOffset(units: number | string, unitType: string, offsetType: 'start' | 'end'): Date {
    const isUnitTypePlural: boolean = unitType[unitType.length - 1] === 's';

    const singularUnitType = !isUnitTypePlural ? unitType : unitType.substring(0, unitType.length - 1);
    const pluralUnitType = isUnitTypePlural ? unitType : unitType.substring(0, unitType.length - 1);

    let dateWithOffset = this.getNow().startOf(singularUnitType as moment.unitOfTime.StartOf);

    if (offsetType === 'start') {
      dateWithOffset = dateWithOffset.subtract(units, pluralUnitType as DurationInputArg2);
    }

    if (offsetType === 'start' && unitType === 'days') {
      dateWithOffset = dateWithOffset.add(1, 'days');
    }

    if (offsetType === 'end' && unitType !== 'days') {
      dateWithOffset = dateWithOffset.subtract(1, 'days');
    }

    return dateWithOffset.toDate();
  }

  getYears(): number[] {
    const startYear = new Date().getFullYear() - 2;

    const years: number[] = [];
    const maxYear = startYear + 10;

    for (let x = startYear; x < maxYear; x++) {
      years[x - startYear] = x;
    }
    return years;
  }

  formatDateForChart(unformattedDate: string, inputDateFormat?: DateFormat): string {
    // YYYYMMDDTHH
    switch (inputDateFormat) {
      case DateFormat.hourly:
        return this.formatHourlyDate(unformattedDate);
      case DateFormat.monthly:
        return this.formatMonthlyDate(unformattedDate);
      case DateFormat.daily:
      case DateFormat.weekly:
        return this.formatDailyOrWeeklyDate(unformattedDate);
      default:
        return '';
    }
  }

  formatToLocalDate(inputDate: MomentInput, inputFormat: string): string {
    return moment(inputDate, inputFormat).format();
  }

  parseDate(date: MomentInput, format: DateFormat) {
    let dateFormat = '';
    switch (format) {
      case DateFormat.daily:
      case DateFormat.weekly:
        dateFormat = 'YYYYMMDD';
        break;
      case DateFormat.hourly:
        dateFormat = 'YYYYMMDDTHH';
        break;
      case DateFormat.monthly:
        dateFormat = 'YYYYMM';
        break;
    }
    return moment(date, dateFormat).toDate();
  }

  formatSecondsAsTime(seconds: number): string {
    if (seconds === 0) {
      return '00:00';
    }
    const date = new Date(0);
    date.setSeconds(seconds);
    let isoDateFormat = date.toISOString().substring(11, 19);
    if (isoDateFormat.startsWith('00:')) {
      // hours
      isoDateFormat = isoDateFormat.substring(3);
      if (isoDateFormat.startsWith('00:')) {
        // minutes
        isoDateFormat = isoDateFormat.substring(2);
      } else if (isoDateFormat.startsWith('0')) {
        isoDateFormat = isoDateFormat.substring(1);
      }
    } else if (isoDateFormat.startsWith('0')) {
      isoDateFormat = isoDateFormat.substring(1);
    }
    return isoDateFormat;
  }

  getTimeZone(): string {
    return moment.tz.guess();
  }

  private formatDailyOrWeeklyDate(unformattedDate: string): string {
    // YYYYMMDD
    if (unformattedDate !== undefined) {
      const year = unformattedDate.substring(0, 4);
      const month = unformattedDate.substring(4, 6);
      let day = unformattedDate.substring(6);

      // Handles stamps with time included
      if (unformattedDate.indexOf('T') > 0) {
        day = day.substring(0, day.indexOf('T'));
      }

      const formattedDate = `${month}/${day}/${year}`;
      const date = new Date(Date.parse(formattedDate));
      const shortMonthName = this.getShortMonthNameFromDate(date);
      return `${shortMonthName} ${parseInt(date.getDate().toString(), 10)}`;
    }
    return '';
  }

  private formatMonthlyDate(unformattedDate: string) {
    // YYYYMM
    const year = unformattedDate.substring(0, 4);
    const month = unformattedDate.substring(4, 6);
    const formattedDate = `${month}/01/${year}`;
    const date = new Date(Date.parse(formattedDate));
    const shortMonthName = this.getShortMonthNameFromDate(date);
    return `${shortMonthName} ${year}`;
  }

  private formatHourlyDate(unformattedDate: string) {
    // YYYYMMDDTHH
    const year = unformattedDate.substring(0, 4);
    const month = unformattedDate.substring(4, 6);
    const day = unformattedDate.substring(6, 8);
    const hours = +unformattedDate.substring(9);
    const amPmTime = this.getAmPmTimeFromHour(hours);
    const formattedDate = `${month}/${day}/${year}`;
    const date = new Date(Date.parse(formattedDate));
    const shortMonthName = this.getShortMonthNameFromDate(date);
    return `${shortMonthName} ${parseInt(date.getDate().toString(), 10)}, ${amPmTime}`;
  }

  private getAmPmTimeFromHour(hours: number) {
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return hours + ' ' + ampm;
  }

  private getShortMonthNameFromDate(date: Date) {
    const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return shortMonthNames[date.getMonth()];
  }

  formatDateToAmericanStandard(date: Date, seperator = '/'): string {
    // MM/DD/YYYY
    return moment(date).format('MM' + seperator + 'DD' + seperator + 'YYYY');
  }

  getUtcOffset(): string {
    return moment().utcOffset().toString();
  }

  dateToNgbDate(date: Date): NgbDate {
    return NgbDate.from({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    }) as NgbDate;
  }

  getLatestDate(givenDateInIsoFormat: string, defaultDateInIsoFormat: string): string {
    const givenDate = new Date(givenDateInIsoFormat);
    const defaultDate = new Date(defaultDateInIsoFormat);
    return givenDate > defaultDate ? givenDateInIsoFormat : defaultDateInIsoFormat;
  }

  ngbDateToMomentDate(date: NgbDateStruct): moment.Moment {
    return moment({ ...date, month: date.month - 1, day: date.day });
  }

  ngbDateToJSDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  startOfLastWeek() {
    const lastWeekDay = this.getNow().subtract(7, 'd');
    const startOfWeek = moment(lastWeekDay).locale('en-US').startOf('week');

    return new Date(startOfWeek.format('YYYY-MM-DD') + 'T00:00:00.000Z');
  }

  endOfLastWeek() {
    const lastWeekDay = this.getNow().subtract(7, 'd');
    const endOfWeek = moment(lastWeekDay).locale('en-US').endOf('week');

    return new Date(endOfWeek.format('YYYY-MM-DD') + 'T00:00:00.000Z');
  }

  toISOStringFixedMs(input: MomentInput): string {
    let date: string = moment(input).toISOString();

    if (moment(input).isBefore(DateUtility.MILLISECONDS_FIXED_DATE)) {
      date = this.removeMillisFromISOString(date);
    }

    return date;
  }

  removeMillisFromISOString(date: string): string {
    return date.slice(0, -5) + 'Z';
  }

  getFridayToFridayWeekForDate(date: MomentInput, removeMilliseconds = true): FromTo {
    const lastFriday = moment(date).startOf('week').subtract(2, 'd').toISOString();
    const thisFriday = moment(date).endOf('week').subtract(1, 'd').toISOString();
    const week: FromTo = {
      from: lastFriday,
      to: thisFriday,
    };

    if (removeMilliseconds) {
      week.from = this.removeMillisFromISOString(week.from as string);
      week.to = this.removeMillisFromISOString(week.to as string);
    }

    return week;
  }

  getPreviousWeekForRange({ from, to }: FromTo, removeMilliseconds = true): FromTo {
    const lastWeek: FromTo = {
      from: moment(from).subtract(7, 'd').toISOString(),
      to: moment(to).subtract(7, 'd').toISOString(),
    };

    if (removeMilliseconds) {
      lastWeek.from = this.removeMillisFromISOString(lastWeek.from as string);
      lastWeek.to = this.removeMillisFromISOString(lastWeek.to as string);
    }

    return lastWeek;
  }

  getNextWeekForRange({ from, to }: FromTo, removeMilliseconds = true): FromTo {
    const nextWeek: FromTo = {
      from: moment(from).add(7, 'd').toISOString(),
      to: moment(to).add(7, 'd').toISOString(),
    };

    if (removeMilliseconds) {
      nextWeek.from = this.removeMillisFromISOString(nextWeek.from as string);
      nextWeek.to = this.removeMillisFromISOString(nextWeek.to as string);
    }

    return nextWeek;
  }

  formatNgbDate(date: NgbDateStruct | null): string {
    let result = '';
    if (date) {
      const day = `${date.day < 10 ? '0' : ''}${date.day}`;
      const month = `${date.month < 10 ? '0' : ''}${date.month}`;
      result = `${date.year}-${month}-${day}`;
    }
    return result;
  }

  parseNgbDate(value?: string): NgbDateStruct | null {
    let result: NgbDateStruct | null = null;
    if (value && value !== 'None') {
      const date = value.split('-');
      result = {
        year: parseInt(date[0]),
        month: parseInt(date[1]),
        day: parseInt(date[2]),
      };
    }
    return result;
  }

  formatNgbTime(time: NgbTimeStruct | null): string {
    let result = '';
    if (time) {
      const hour = `${time.hour < 10 ? '0' : ''}${time.hour}`;
      const minute = `${time.minute < 10 ? '0' : ''}${time.minute}`;
      const second = `${time.second < 10 ? '0' : ''}${time.second}`;
      result = `${hour}:${minute}:${second}`;
    }
    return result;
  }

  parseNgbTime(value?: string): NgbTimeStruct | null {
    let result: NgbTimeStruct | null = null;
    if (value && value !== 'None') {
      const date = new Date(value);
      result = {
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      };
    }
    return result;
  }

  isSameDate(first: MomentInput, second: MomentInput): boolean {
    return moment(first).isSame(moment(second), 'date');
  }

  isDateValid(value: string): boolean {
    return !isNaN(Date.parse(value));
  }

  isDateFormatValid(value: string): boolean {
    const usDate = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/;
    return usDate.test(value);
  }

  moveDate(value: MomentInput, amount: number, unit: unitOfTime.DurationConstructor): moment.Moment {
    return moment(value).add(amount, unit);
  }

  getFirstDayOfThisMonth(): Date {
    return this.getNow().startOf('month').toDate();
  }

  getLastDayOfThisMonth(): Date {
    return this.getNow().endOf('month').toDate();
  }

  isToday(date: MomentInput): boolean {
    return moment(date).isSame(this.getNow(), 'day');
  }

  isPastDueToday(date: MomentInput): boolean {
    return moment(date).isBefore(this.getNow(), 'day');
  }

  inputDateTransformFn = (value: unknown) => {
    return isString(value) ? value : '';
  };

  outputDateTransformFn = (value: unknown) => {
    const date = isString(value) ? value : '';
    const chars = date.split('');
    return `${chars.slice(0, 2).join('')}/${chars.slice(2, 4).join('')}/${chars.slice(4).join('')}`;
  };

  private getNow() {
    return moment();
  }

  private log(message: string) {
    console.log(message);
  }
}
