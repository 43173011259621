<div class="position-relative" style="width: fit-content">
  <canvas [width]="width ?? 300" [height]="height ?? 130" style="border: 1px dashed black" #signatureCanvas></canvas>
  <div class="text-center">{{ signatureTitle ?? 'Your signature' }}</div>
  @if (signatureSubtitle) {
    <div class="text-center text-muted" style="font-size: 0.7rem">{{ signatureSubtitle }}</div>
  }
  @if (!signatureIsEmpty) {
    <a class="d-block position-absolute" style="top: 1rem; right: 1rem; font-size: 1.2rem" (click)="onClear()">
      <i class="fa-solid fa-arrow-rotate-left"></i>
    </a>
  }
</div>
