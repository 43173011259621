import { getAtob } from '../../globalHelpers/index.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const base64Decoder = {
    convert(input) {
        return getAtob()(input);
    },
};

export { base64Decoder };

