export enum CodeRequestResultType {
  AppealComplete = 'Appeal Complete',
  AppealPending = 'Appeal Pending',
  Consignment = 'Consignment',
  Duplicate = 'Duplicate',
  Incomplete = 'Incomplete',
  InProgress = 'In Progress',
  NotTransferred = 'Not Transferred',
  PaApproved = 'PA Approved',
  PaPending = 'PA Pending',
  PaReady = 'PA Ready',
  Paid = 'Paid',
  PaidEligible = 'Paid Eligible',
  Rejected = 'Rejected',
  Reversed = 'Reversed',
  Transferred = 'Transferred',
}
