import { Injectable } from '@angular/core';
import { IPharmacy } from '@shared/models/pharmacy.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponRequestStateService {
  static readonly DEFAULT_ROUTE = 'retail/application';
  static readonly EXPIRED_LINK_ROUTE = 'retail/expired-link';
  static readonly ERRORED_LINK_ROUTE = 'retail/errored-link';
  static readonly FINISHED_LINK_ROUTE = 'retail/finished-link';
  couponRequestInProgress = new BehaviorSubject<boolean>(false);
  copayCodeRequestServiceReset = new BehaviorSubject<boolean>(false);
  retailStateServiceReset = new BehaviorSubject<boolean>(false);
  userPharmacies = new BehaviorSubject<IPharmacy[]>([]);

  constructor() {}

  startCouponRequest(): void {
    this.couponRequestInProgress.next(true);
  }

  isCouponRequestInProgress(): boolean {
    return this.couponRequestInProgress.getValue();
  }

  reset(): void {
    this.couponRequestInProgress.next(false);
    this.copayCodeRequestServiceReset.next(false);
    this.retailStateServiceReset.next(true);
    this.userPharmacies.next([]);
  }
}
