import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'signature-pad',
  standalone: true,
  templateUrl: 'signature-pad.component.html',
})
export class SignaturePadComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild('signatureCanvas') canvas: ElementRef<HTMLCanvasElement>;
  @Input() width?: number;
  @Input() height?: number;
  @Input() signatureTitle?: string;
  @Input() signatureSubtitle?: string;
  @Input() type: 'jpeg' | 'png' = 'png';
  @Output() signatureChanged = new EventEmitter<string | undefined>();
  signaturePad: SignaturePad;
  signatureIsEmpty: boolean = true;

  ngAfterViewInit() {
    const backgroundColor = this.type === 'jpeg' ? '#FFFFFF' : undefined;
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, { backgroundColor });
    this.signaturePad.addEventListener('endStroke', this.onEndStroke);
  }

  ngOnDestroy() {
    this.signaturePad.removeEventListener('endStroke', this.onEndStroke);
  }

  private onEndStroke = () => {
    this.signatureIsEmpty = false;
    this.signatureChanged.emit(this.signaturePad.toDataURL(this.type === 'jpeg' ? 'image/jpeg' : undefined));
  };

  onClear() {
    this.signatureIsEmpty = true;
    this.signaturePad.clear();
    this.signatureChanged.emit(undefined);
  }
}
